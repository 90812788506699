






























































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup() {
    return {};
  },
});
